<template>
  <div>
    <div class="site-mobile-menu site-navbar-target">
      <div class="site-mobile-menu-header">
        <div class="site-mobile-menu-close mt-3">
          <span class="icon-close2 js-menu-toggle"></span>
        </div>
      </div>
      <div class="site-mobile-menu-body"></div>
    </div>
    <header class="site-navbar mt-3" id="top">
      <div class="container-fluid">
        <div class="row align-items-center">
          <nav class="mx-auto site-navigation">
            <ul class="site-menu js-clone-nav d-none d-lg-block">
              <li v-for="l in topMenu" :key="l.to" >
<!--                <router-link :to="{ name: l.to }">{{l.title}}</router-link>-->
                <a :href="$router.resolve({ name: l.to }).href">{{l.title}}</a>
              </li>
            </ul>
          </nav>
          <div class="col-6 site-burger-menu d-block d-lg-none text-right">
            <a href="#" class="site-menu-toggle js-menu-toggle"
              ><span class="icon-menu h3"></span
            ></a>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  name: "ponuka-kamena",
  data() {
    return {
      ponukaKamena: null,
      info: null,
      topMenu : null,
    };
  },
  async mounted() {
    this.info = await this.$axios
      .get("/data/info.json")
      .then((response) => (response.data))
      .catch(function (error) {
        console.log(error);
      });
    this.$axios
        .get("/data/ponuka-kamena.json")
        .then((response) => this.setData(response.data))
        .catch(function (error) {
          console.log(error);
        });
  },
  methods: {
    setData(data) {
      this.ponukaKamena = data;
      this.topMenu = [];
      this.info.menu.forEach(mi => {
        if (mi.showOnTop) {
          this.topMenu.push(mi);
        }
      });
    },
  },
};
</script>